<template>
  <v-card>
    <v-card-title class="justify-center mb-2">
      Cadastrar novo
    </v-card-title>

    <v-card-text>
      <v-autocomplete
        v-model="documentIdSelected"
        :items="documentList"
        item-text="description"
        item-value="id"
        label="Documento"
        outlined
        dense
      />

      <v-autocomplete
        v-model="processIdSelected"
        :items="processList"
        item-text="description"
        item-value="id"
        label="Processo"
        outlined
        dense
      />

      <v-autocomplete
        v-model="revisorUser"
        :items="employeeList"
        item-text="name"
        item-value="id"
        label="Usuário revisor"
        outlined
        dense
      />

      <v-autocomplete
        v-model="approvingUser"
        :items="employeeList"
        item-text="name"
        item-value="id"
        label="Usuário aprovador"
        outlined
        dense
      />

      <v-file-input
        v-model="fileInput"
        label="Adicionar arquivo"
        outlined
        dense
      />

      <div class="d-flex justify-end mt-2">
        <v-btn
          color="info"
          @click="sendModalData()"
        >
          <span
            v-if="!isLoadingSendFile"
          >Enviar</span>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>

        <v-btn
          color="error"
          class="ml-5"
          @click="closeModal()"
        >
          Cancelar
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'

export default {
  mixins: [formatters, messages],

  props: {
    employeeList: {
      type: [Object, Array],
      required: true,
    },

    processList: {
      type: [Object, Array],
      required: true,
    },

    documentList: {
      type: [Object, Array],
      required: true,
    },
  },

  data() {
    return {
      revisorUser: '',
      approvingUser: '',
      processIdSelected: '',
      documentIdSelected: '',

      fileInput: [],
      responseId: '',

      isLoadingSendFile: false,
      containsInputFile: false,
    }
  },

  watch: {
    fileInput() {
      this.containsInputFile = !!this.fileInput

      return this.containsInputFile
    },

    immediate: true,
  },

  methods: {
    async sendFile(id) {
      const formData = new FormData()
      const FileInput = []

      FileInput.push(this.fileInput)
      FileInput.push(id)

      FileInput.forEach(file => {
        formData.append('files[]', file)
        formData.append('id', id)
      })

      await axiosIns.post('/api/v1/sgq/file/submit_files', formData)
        .then(() => {
          this.clearData()
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.closeModal()
        })
    },

    async sendModalData() {
      const {
        containsInputFile, processIdSelected, approvingUser, revisorUser, documentIdSelected,
      } = this

      if (!containsInputFile) {
        this.showMessage({
          icon: 'error',
          title: 'O campo arquivo não pode ficar vazio!',
          text: 'Tente novamente',
          timer: 3000,
        })
      }

      const body = {
        process_id: processIdSelected,
        users_approve_id: approvingUser,
        users_revision_id: revisorUser,
        documents_id: documentIdSelected,
      }

      this.isLoadingSendFile = true

      await axiosIns.post('/api/v1/sgq/file/store', body)
        .then(response => {
          this.responseId = response.data.data.id

          this.showMessage({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Sua resposta enviada com sucesso!',
          })

          this.updatedTable()
        })
        .catch(error => {
          this.showMessage({
            icon: 'error',
            title: 'Erro ao enviar dados!',
            text: error,
          })
        })
        .finally(() => {
          this.sendFile(this.responseId)

          this.isLoadingSendFile = false
          this.clearData()
          this.closeModal()
        })
    },

    clearData() {
      this.fileInput = []

      this.processIdSelected = ''
      this.approvingUser = ''
      this.revisorUser = ''
    },
  },
}
</script>
