<template>
  <div>
    <v-card class="mb-6">
      <v-card-title class="pt-0 pb-0">
        <span> Arquivos </span>

        <v-spacer />

        <v-text-field
          v-model="filterData"
          class="mt-7 mr-4"
          label="Buscar por Processo, Documento "
          outlined
          dense
          @keyup.enter="findFilterResult()"
        />

        <v-btn
          color="info"
          outlined
          @click="openModal('create')"
        >
          <v-icon
            size="30"
            class="me-2"
          >
            {{ icons.mdiPlaylistPlus }}
          </v-icon>
          Novo
        </v-btn>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="listOfFilteredItems"
        :loading="isLoadingTableData"
        :sort-by="'document.description'"
        loading-text="Carregando dados..."
      >
        <template v-slot:no-data>
          <span>Sem informações para exibir</span>
        </template>

        <template v-slot:item.show_document="{ item }">
          <v-icon
            v-if="item.id === isLoadingFile ? false : true"
            medium
            class="me-2"
            color="warning"
            @click="showFileInBrowser(item)"
          >
            {{ icons.mdiEye }}
          </v-icon>
          <v-progress-circular
            v-else
            color="warning"
            indeterminate
          >
          </v-progress-circular>
        </template>

        <template v-slot:item.edit="{ item }">
          <v-icon
            medium
            class="me-2"
            color="info"
            @click="openModal('details',item)"
          >
            {{ icons.mdiPencil }}
          </v-icon>
        </template>

        <template v-slot:item.delete="{ item }">
          <v-icon
            medium
            class="me-2"
            color="error"
            @click="deleteRegister(endpointDelete, item.id)"
          >
            {{ icons.mdiDelete }}
          </v-icon>
        </template>

        <template v-slot:item.updated_at="{ item }">
          {{ !item.created_at ? '' : dateFormat(item.created_at) }}
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="showFileModal"
      width="500px"
    >
      <FileModal
        :employee-list="employeeList"
        :process-list="processList"
        :document-list="documentList"
        @updatedTable="updatedTable()"
        @close="showFileModal = false"
      ></FileModal>
    </v-dialog>

    <v-dialog
      v-model="showFileDetails"
      width="500px"
    >
      <FileDetails
        :key="fileDetails.id"
        :data="fileDetails"
        :employee-list="employeeList"
        :process-list="processList"
        :document-list="documentList"
        @updatedTable="updatedTable()"
        @close="showFileDetails = false"
      ></FileDetails>
    </v-dialog>
  </div>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import {
  mdiDelete, mdiEye, mdiPencil, mdiPlaylistPlus,
} from '@mdi/js'
import { saveAs } from 'file-saver'
import localStorageSlim from 'localstorage-slim'
import FileDetails from './FileDetails.vue'
import FileModal from './FileModal.vue'

export default {
  components: {
    FileModal,
    FileDetails,
  },

  mixins: [formatters, messages],

  data() {
    return {
      filterData: '',
      fileDetails: {},
      endpointDelete: '/api/v1/sgq/file/destroy',

      processList: [],
      employeeList: [],
      documentList: [],

      headers: [
        {
          text: 'PROCESSO',
          value: 'process.description',
          sortable: false,
          align: 'center',
        },
        {
          text: 'DOCUMENTO',
          value: 'document.description',
          sortable: true,
          align: 'center',
        },
        {
          text: 'REVISOR',
          value: 'users_revision.name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'APROVADOR',
          value: 'users_approve.name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'VISUALIZAR DOCUMENTO',
          value: 'show_document',
          sortable: false,
          align: 'center',
        },
        {
          text: 'EDITAR',
          value: 'edit',
          sortable: false,
          align: 'center',
        },
        {
          text: 'DELETAR',
          value: 'delete',
          sortable: false,
          align: 'center',
        },
      ],

      itemsTable: [],
      listOfFilteredItems: [],

      isLoadingFile: false,
      showFileModal: false,
      showFileDetails: false,
      isLoadingTableData: false,

      icons: {
        mdiPlaylistPlus,
        mdiPencil,
        mdiDelete,
        mdiEye,
      },
    }
  },

  watch: {
    filterData() {
      this.findFilterResult()
    },
  },

  created() {
    this.employeeList = localStorageSlim.get('listEmployees', { decrypt: true })

    this.getItemsTable()
    this.getProcesses()
    this.getDocumentsList()
  },

  methods: {
    async getItemsTable() {
      this.isLoadingTableData = true

      await axiosIns
        .get('/api/v1/sgq/file/index')
        .then(response => {
          const { data } = response.data
          this.itemsTable.length = 0

          data.forEach(
            ({
              id,
              process,
              document,
              process_id: processId,
              users_approve: usersApprove,
              users_revision: usersRevision,
              users_approve_id: usersApproveId,
              users_revision_id: usersRevisionId,
            }) => this.itemsTable.push({
              id,
              process_id: processId,
              users_approve_id: usersApproveId,
              users_revision_id: usersRevisionId,
              process: { id: process.id, description: process.description },
              document: {
                id: document.id,
                type: document.type.name,
                description: `${document.identify} - ${document.description}`,
              },
              users_approve: { name: usersApprove.name },
              users_revision: { name: usersRevision.name },
            }),
          )

          this.listOfFilteredItems = [...this.itemsTable]
        })
        .catch(error => { this.showErrorMessage(error) })
        .finally(() => {
          this.isLoadingTableData = false
        })
    },

    async getProcesses() {
      await axiosIns
        .get('/api/v1/sgq/process/index')
        .then(response => {
          this.processList = response.data.data
        })
        .catch(error => { this.showErrorMessage(error) })
    },

    async getDocumentsList() {
      await axiosIns.get('/api/v1/sgq/document/index').then(response => {
        const { data } = response.data

        data.forEach(({ id, identify, description }) => {
          this.documentList.push({
            id,
            description: `${identify} - ${description}`,
          })
        })
      })
        .catch(error => this.showErrorMessage(error))
    },

    findFilterResult() {
      const { filterData, itemsTable } = this
      const filter = filterData.toLowerCase()

      const filteredItems = itemsTable.filter(({ document, process }) => {
        const documentDescription = document.description.toLowerCase()
        const processDescription = process.description.toLowerCase()

        return documentDescription.includes(filter) || processDescription.includes(filter)
      })

      this.listOfFilteredItems = filteredItems
    },

    /**
     * Verify if the document is of a specific form type.
     *
     * @param {string} type - The type of the document to verify.
     * @return {boolean} Returns true if the document is of the specified form type.
     */
    verifyDocumentIsPdf(type) {
      return type === 'pdf'
    },

    async showFileInBrowser(data) {
      const { id } = data

      this.isLoadingFile = id

      const body = {
        id,
      }

      await axiosIns
        .post('/api/v1/sgq/file/view_files', body, { responseType: 'arraybuffer' })
        .then(response => {
          const contentType = response.headers['content-type']
          const fileType = contentType.split('/')[1]
          const { data: responseData } = response

          if (this.verifyDocumentIsPdf(fileType)) {
            const file = this.createFile({ data: responseData, contentType, fileType })
            const urlFromFile = URL.createObjectURL(file)
            window.open(urlFromFile, '__blank')

            return
          }

          const file = this.createFile({ data: responseData, contentType, fileType })
          this.downloadFile(file)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.isLoadingFile = ''
        })
    },

    createFile({ data, contentType, fileType }) {
      const file = new File([data], `document.${fileType}`, {
        type: contentType,
      })

      return file
    },

    downloadFile(file) {
      saveAs(file)
    },

    updatedTable() {
      this.getItemsTable()
    },

    openModal(type, data = {}) {
      const modal = {
        create: () => {
          this.showFileModal = true
        },
        details: () => {
          this.showFileDetails = true
        },
      }

      if (type in modal) {
        modal[type]()
        this.fileDetails = data

        return
      }

      console.error(`Invalid modal type: ${type}`)
    },
  },
}
</script>
