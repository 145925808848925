import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"justify-center mb-2"},[_vm._v(" Cadastrar novo ")]),_c(VCardText,[_c(VAutocomplete,{attrs:{"items":_vm.documentList,"item-text":"description","item-value":"id","label":"Documento","outlined":"","dense":""},model:{value:(_vm.documentIdSelected),callback:function ($$v) {_vm.documentIdSelected=$$v},expression:"documentIdSelected"}}),_c(VAutocomplete,{attrs:{"items":_vm.processList,"item-text":"description","item-value":"id","label":"Processo","outlined":"","dense":""},model:{value:(_vm.processIdSelected),callback:function ($$v) {_vm.processIdSelected=$$v},expression:"processIdSelected"}}),_c(VAutocomplete,{attrs:{"items":_vm.employeeList,"item-text":"name","item-value":"id","label":"Usuário revisor","outlined":"","dense":""},model:{value:(_vm.revisorUser),callback:function ($$v) {_vm.revisorUser=$$v},expression:"revisorUser"}}),_c(VAutocomplete,{attrs:{"items":_vm.employeeList,"item-text":"name","item-value":"id","label":"Usuário aprovador","outlined":"","dense":""},model:{value:(_vm.approvingUser),callback:function ($$v) {_vm.approvingUser=$$v},expression:"approvingUser"}}),_c(VFileInput,{attrs:{"label":"Adicionar arquivo","outlined":"","dense":""},model:{value:(_vm.fileInput),callback:function ($$v) {_vm.fileInput=$$v},expression:"fileInput"}}),_c('div',{staticClass:"d-flex justify-end mt-2"},[_c(VBtn,{attrs:{"color":"info"},on:{"click":function($event){return _vm.sendModalData()}}},[(!_vm.isLoadingSendFile)?_c('span',[_vm._v("Enviar")]):_c(VProgressCircular,{attrs:{"color":"white","indeterminate":""}})],1),_c(VBtn,{staticClass:"ml-5",attrs:{"color":"error"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" Cancelar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }