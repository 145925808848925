import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,{staticClass:"mb-6"},[_c(VCardTitle,{staticClass:"pt-0 pb-0"},[_c('span',[_vm._v(" Arquivos ")]),_c(VSpacer),_c(VTextField,{staticClass:"mt-7 mr-4",attrs:{"label":"Buscar por Processo, Documento ","outlined":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.findFilterResult()}},model:{value:(_vm.filterData),callback:function ($$v) {_vm.filterData=$$v},expression:"filterData"}}),_c(VBtn,{attrs:{"color":"info","outlined":""},on:{"click":function($event){return _vm.openModal('create')}}},[_c(VIcon,{staticClass:"me-2",attrs:{"size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlaylistPlus)+" ")]),_vm._v(" Novo ")],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.listOfFilteredItems,"loading":_vm.isLoadingTableData,"sort-by":'document.description',"loading-text":"Carregando dados..."},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('span',[_vm._v("Sem informações para exibir")])]},proxy:true},{key:"item.show_document",fn:function(ref){
var item = ref.item;
return [(item.id === _vm.isLoadingFile ? false : true)?_c(VIcon,{staticClass:"me-2",attrs:{"medium":"","color":"warning"},on:{"click":function($event){return _vm.showFileInBrowser(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiEye)+" ")]):_c(VProgressCircular,{attrs:{"color":"warning","indeterminate":""}})]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{staticClass:"me-2",attrs:{"medium":"","color":"info"},on:{"click":function($event){return _vm.openModal('details',item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{staticClass:"me-2",attrs:{"medium":"","color":"error"},on:{"click":function($event){return _vm.deleteRegister(_vm.endpointDelete, item.id)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(!item.created_at ? '' : _vm.dateFormat(item.created_at))+" ")]}}])})],1),_c(VDialog,{attrs:{"width":"500px"},model:{value:(_vm.showFileModal),callback:function ($$v) {_vm.showFileModal=$$v},expression:"showFileModal"}},[_c('FileModal',{attrs:{"employee-list":_vm.employeeList,"process-list":_vm.processList,"document-list":_vm.documentList},on:{"updatedTable":function($event){return _vm.updatedTable()},"close":function($event){_vm.showFileModal = false}}})],1),_c(VDialog,{attrs:{"width":"500px"},model:{value:(_vm.showFileDetails),callback:function ($$v) {_vm.showFileDetails=$$v},expression:"showFileDetails"}},[_c('FileDetails',{key:_vm.fileDetails.id,attrs:{"data":_vm.fileDetails,"employee-list":_vm.employeeList,"process-list":_vm.processList,"document-list":_vm.documentList},on:{"updatedTable":function($event){return _vm.updatedTable()},"close":function($event){_vm.showFileDetails = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }