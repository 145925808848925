<template>
  <v-card>
    <v-card-title class="mb-2">
      <span>Detalhes</span>
      <v-spacer />

      <v-tooltip
        left
        color="warning"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            @click="expand = !expand"
          >
            <v-icon
              color="warning"
              v-bind="attrs"
              v-on="on"
            >
              {{ icons.mdiPencil }}
            </v-icon>
          </v-btn>
        </template>

        <span>Editar</span>
      </v-tooltip>
    </v-card-title>

    <v-card-text>
      <v-text-field
        v-model="data.process.description"
        label="Processo"
        readonly
        outlined
        dense
      />

      <v-text-field
        v-model="data.users_approve.name"
        label="Aprovador"
        readonly
        outlined
        dense
      />

      <v-text-field
        v-model="data.users_revision.name"
        label="Revisor"
        readonly
        outlined
        dense
      />

      <v-text-field
        v-model="identifyingAndDescriptionFromDocument"
        label="Documento"
        readonly
        outlined
        dense
      />

      <v-divider />
      <br>

      <v-expand-transition>
        <v-card
          v-show="expand"
        >
          <v-autocomplete
            v-model="processIdSelected"
            :items="processList"
            item-text="description"
            item-value="id"
            label="Alterar processo"
            outlined
            dense
          />

          <v-autocomplete
            v-model="revisorUser"
            :items="employeeList"
            item-text="name"
            item-value="id"
            label="Alterar revisor"
            outlined
            dense
          />

          <v-autocomplete
            v-model="approvingUser"
            :items="employeeList"
            item-text="name"
            item-value="id"
            label="Alterar aprovador"
            outlined
            dense
          />

          <v-autocomplete
            v-model="documentIdSelected"
            :items="documentList"
            item-text="description"
            item-value="id"
            label="Alterar documento"
            outlined
            dense
          />

          <v-file-input
            v-model="fileInput"
            label="Alterar arquivo"
            outlined
            dense
          />
        </v-card>
      </v-expand-transition>

      <div class="d-flex justify-end mt-2">
        <v-btn
          color="info"
          @click="sendModalData()"
        >
          <span
            v-if="!isLoading"
          >Enviar</span>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>

        <v-btn
          color="error"
          class="ml-5"
          @click="closeModal()"
        >
          Cancelar
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiPencil } from '@mdi/js'

export default {
  mixins: [formatters, messages],

  props: {
    data: {
      type: [Object, Array],
      required: true,
    },

    employeeList: {
      type: [Object, Array],
      required: true,
    },

    processList: {
      type: [Object, Array],
      required: true,
    },

    documentList: {
      type: [Object, Array],
      required: true,
    },
  },

  data() {
    return {
      revisorUser: '',
      approvingUser: '',
      processIdSelected: '',
      nameOfDocumentType: '',
      documentIdSelected: '',

      fileInput: [],

      expand: false,
      isLoading: false,
      containsInputFile: false,

      icons: {
        mdiPencil,
      },
    }
  },

  computed: {
    identifyingAndDescriptionFromDocument() {
      const { description } = this.data.document

      return description ?? ''
    },
  },

  watch: {
    fileInput() {
      this.containsInputFile = !!this.fileInput
    },

    immediate: true,
  },

  methods: {
    async sendFile(id) {
      const formData = new FormData()
      const files = [id, this.fileInput]

      files.forEach(file => {
        formData.append('files[]', file)
        formData.append('id', id)
      })

      await axiosIns.post('/api/v1/sgq/file/submit_files', formData)
        .then(() => {
          this.clearData()
        })
        .catch(error => { this.showErrorMessage(error) })
        .finally(() => { this.closeModal() })
    },

    async sendModalData() {
      this.isLoading = true

      const {
        processIdSelected, approvingUser, revisorUser, documentIdSelected, data, containsInputFile,
      } = this

      const body = {
        process_id: processIdSelected || data.process.id,
        users_approve_id: approvingUser || data.users_approve_id,
        users_revision_id: revisorUser || data.users_revision_id,
        documents_id: documentIdSelected || data.document.id,
      }

      await axiosIns.put(`/api/v1/sgq/file/update/${this.data.id}`, body)
        .then(() => {
          this.showMessage({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Sua resposta enviada com sucesso!',
            timer: 3000,
          })

          this.updatedTable()
        })
        .catch(error => {
          this.showMessage({
            icon: 'error',
            title: 'Erro ao enviar dados!',
            text: error,
          })
        })
        .finally(() => {
          this.isLoading = false

          if (containsInputFile) {
            this.sendFile(data.id)
          }

          this.clearData()
          this.closeModal()
        })
    },

    clearData() {
      this.fileInput = []
      this.processIdSelected = ''
      this.approvingUser = ''
      this.revisorUser = ''
    },
  },
}
</script>
